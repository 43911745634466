@import "bootstrap-icons/font/bootstrap-icons";
@import "bootstrap/dist/css/bootstrap.min.css";

* {
    --head-foot-h: 100px;
    --side-right-w: 450px;
    --side-left-w: 100px;
    --week-h: 190px;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
}

.app {
    position: relative;
    height: 100svh;
    overflow: hidden;
}

.participant-avatar {
    position: relative;
    width: 55px;
    height: 55px;
    min-width: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #000c38;
    color: #fff;
}

.participant-avatar.no-background {
    background-color: #fff !important;
}

.header .participant-avatar {
    cursor: pointer;
}

.participant-avatar__avatar-initials {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    font-weight: 700;
    line-height: normal;
    color: #fff;
}

.participant-avatar img {
    position: absolute;
    width: calc(100% - 0px);
    height: calc(100% - 0px);
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
}

.participant-number {
    background: #dfebff !important;
    color: #000c38;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
}

.notification-list {
    position: fixed;
    top: 32px;
    right: 0;
    z-index: 1060;
}

.notification-dialog {
    /* position: fixed;
    top: 32px;
    right: 0; */
    margin-bottom: 10px;
    width: 768px;
    max-width: 100svw;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    border-left: 4px solid #000c38;
    border-radius: 4px;
    box-shadow: 0px 0px 32px 0px #d3cfe8;
    background: #f1f0f7;
    /* opacity: 0; */
}

.notification-dialog.active {
    animation-name: dialog-in;
    animation-duration: 0.3s;
}

.notification-dialog__body {
    display: flex;
    align-items: center;
}

.notification-dialog__icon {
    margin-right: 20px;
    font-size: 20px;
    color: #000c38;
}

.notification-dialog__text {
    color: #000c38;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.notification-dialog__close {
    font-size: 16px;
    cursor: pointer;
}

@keyframes dialog-in {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.btn {
    background-color: #d9dfe7;
}

.btn:hover {
    background-color: #d9dfe7;
}

.btn-forgot-password {
    margin-top: 10px;
}

.btn-forgot-password span {
    cursor: pointer;
    text-decoration: underline;
}

.dropdown,
.dropdown-center,
.dropend,
.dropstart,
.dropup,
.dropup-center {
    position: static;
}

.form-control:focus {
    border-color: #244220;
    box-shadow: none;
}

.nav-link:hover:not(.nav-link.active) {
    background-color: #fff;
}

.nav-link.active {
    cursor: default;
}

.nav-link {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem 0.75rem 2rem;
}

@media (max-width: 768px) {
    .nav-link {
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        width: 100%;
    }
}

.nav-link i:before {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    border-radius: 50%;
    border: 1px solid #fff;
    width: 21px;
    height: 21px;
    box-sizing: content-box;
    font-size: 18px;
}

.nav-link:not(.nav-link.active) i:before {
    border: 1px solid #000c38;
    border-radius: 50%;
    background-color: #f1f0f0;
}

.nav-link.active {
    border-color: #000c38 !important;
}

.nav-link--home {
    background-color: #dfebff;
}

.nav-link--home:hover {
}

.dropdown-menu.show {
    padding: 0.75rem 0;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.dropdown-menu .dropdown-item {
    padding: 0.5rem 1rem;
}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: #f6f6f6 !important;
}

.dropdown-item:active {
    color: var(--bs-dropdown-link-color);
}

.accordion-item,
.accordion-button {
    background-color: transparent;
}

.accordion-button {
    border-radius: 40px !important;
    font-size: 1.25rem !important;
}

.accordion-button > span:last-of-type {
    font-size: 1rem !important;
    font-weight: 400;
}

.accordion-button:not(.collapsed) {
    background-color: #000c38 !important;
    color: #fff;
}

.accordion-button::after {
    background-image: none;
    font-family: "bootstrap-icons";
    content: "\F64D";
}

.accordion-button:not(.collapsed)::after {
    content: "\F63B";
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-body {
    padding: 1rem 0;
}

.collapse-toggle {
    transition: 0.1s !important;
}

.collapse-toggle div[type="button"] {
    width: fit-content;
}

thead {
    /* margin-bottom: 1rem; */
}

th,
td {
    background-color: transparent !important;
}

td .participant-avatar {
    width: 40px;
    height: 40px;
    min-width: 40px;
}

td .participant-avatar i {
    font-size: 30px;
}

table .dropdown-toggle::after {
    content: none;
}

table tbody tr:last-of-type {
    border-color: transparent;
}

table .form-check-input {
    width: 1.25rem;
    height: 1.25rem;
    margin-top: 0.15rem;
    box-shadow: none !important;
    border-color: #000c38;
    cursor: pointer;
}

table .form-check-input:focus {
    border-color: #000c38;
}

table .form-check-input:checked {
    background-color: #000c38;
    border-color: #000c38;
}

.btn {
    border-radius: 50px;
    padding: 0.5rem 1rem;
}

/* .footer_admin .btn {
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
} */

.btn-cancel {
    background-color: #eb5757;
    border-color: #eb5757;
}

.btn-cancel:hover,
.btn-cancel:active {
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
    color: #fff !important;
}

.table .btn {
    padding: 0.5rem 0.75rem;
}

.custom-file {
    display: flex;
    justify-content: center;
}

.custom-file-label {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 160px;
    height: 160px;
    padding: 0.5rem 0.75rem;
    border: 2px dashed #244220;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
}

.custom-file-label strong {
    color: #244220;
}

.custom-file-label i {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 75px;
    color: #dfebff;
}

.modal-body {
    max-height: calc(100svh - 200px);
    overflow-y: auto;
}

.modal-header {
    background-color: #00d1e1;
    color: #fff;
}

.form-switch .form-check-input {
    width: 2.75rem;
    height: 1.5rem;
    margin-top: 0;
    box-shadow: none !important;
    cursor: pointer;
}

.form-switch .form-check-input:checked {
    background-color: #00d1e1;
    border-color: #00d1e1;
}

.form-switch .form-check-input:focus {
    border-color: #00d1e1;
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:focus:checked {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-switch .form-check-input + label.form-check-label {
    margin-left: 0.75rem;
    cursor: pointer;
}

.tab-pane .input-group .btn {
    min-width: 140px;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
    /* display: none; */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: #f1f0f0;
    /* background: transparent; */
    /* opacity: 0.8; */
}

#preloader:before {
    --radius: 120px;
    content: "";
    position: absolute;
    top: calc(50% - (var(--radius) / 2));
    left: calc(50% - (var(--radius) / 2));
    border: 12px solid #4b0a58;
    /* border-top-color: #f1f0f0; */
    border-top-color: transparent;
    border-radius: 50%;
    width: var(--radius);
    height: var(--radius);
    /* background-color: #f1f0f0; */
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-loader {
    0% {
        transform: translateY(-50%) rotate(0deg);
    }

    100% {
        transform: translateY(-50%) rotate(360deg);
    }
}

.fade-out {
    animation: fadeOut 0.6s ease forwards;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        display: none;
    }
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: #00000075;
    visibility: visible;
    transition:
        visibility 0.3s,
        opacity 0.3s;
}
.loader:before {
    content: "";
    position: fixed;
    top: calc(50% - 2.5rem);
    left: calc(50% - 2.5rem);
    width: 5rem;
    height: 5rem;
    border: 6px solid #fff;
    border-top-color: #000c38;
    border-radius: 50%;
    animation: animate-preloader 1s linear infinite;
}

.loader-locale {
    position: absolute;
    top: 50%;
    right: 10px;
    visibility: visible;
    width: 1.5rem;
    height: 1.5rem;
    border: 2px solid #dadada;
    border-top-color: #000c38;
    border-radius: 50%;
    animation: animate-loader 1s linear infinite;
    transform: translateY(-50%);
    transition:
        visibility 0.3s,
        opacity 0.3s;
}

.loader.inactive,
.loader-locale.inactive {
    opacity: 0;
    visibility: hidden;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
    position: relative;
    height: var(--head-foot-h);
    background-color: #fff;
    z-index: 100;
}

.header.welcome .header__content-participants {
    display: none;
}

.header__content {
    height: 100%;
    display: flex;
}

.header__content-main {
    flex-grow: 1;
    display: flex;
    position: relative;
}

.header__content-line.meeting {
    display: none;
}

.header__content-line {
    width: 100%;
    height: 1px;
    position: absolute;
    background-color: #dcdfe3;
    bottom: 0;
    left: 120px;
}

.header__content-title {
    padding-left: calc(var(--side-left-w) + 20px);
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 25px;
}

.header__content-title .btn {
    /* margin-left: 10px; */
    display: flex;
}

.header__content-title .btn i {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.header__content-title-main {
    /* padding-left: calc(var(--side-left-w) + 20px); */
    margin-bottom: 5px;
    color: #000c38;
    font-size: 30px;
    font-weight: 400;
    line-height: normal;
}

.header__content-title-main span {
    font-weight: 700;
}

.header__content-user {
    width: var(--side-right-w);
    min-width: var(--side-right-w);
    height: 100%;
    padding-right: 25px;
    padding-left: 25px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.header__content-timing {
    margin-right: 20px;
    color: #000c38;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
}

.header__content-user-profile {
    height: 80px;
    /* width: 100%; */
    padding-left: 17px;
    padding-right: 17px;
    display: flex;
    align-items: center;
    /* justify-content: flex-end; */
}

/*--------------------------------------------------------------
# Main
--------------------------------------------------------------*/
.main {
    position: relative;
    left: var(--side-left-w);
    height: calc(95svh - ((var(--head-foot-h) * 2)));
    width: calc(100vw - (var(--side-right-w)) - var(--side-left-w));
    overflow: hidden;
}

.main.welcome {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: calc(100vw - var(--side-left-w));
    height: calc(100svh - 50px - ((var(--head-foot-h))));
}

.main.welcome .main__container {
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.main.welcome .main__container-welcome {
    width: 40vw;
    padding: 5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 20px; */
}

.main.welcome .main__container-welcome-text {
    /* margin-bottom: 30px; */
    /* padding: 50px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 20px; */
}

.main.welcome .main__container-welcome-h {
    padding: 10px 0px;
    color: #000c38;
    font-size: 36px;
    text-align: center;
}

.main.welcome .main__container-welcome-p {
    color: #000c38;
    font-size: 18px;
    font-weight: 600;
}

.main__container-welcome-button {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
    align-items: center;
    padding: 4px;
    background-color: #000c38;
    border-radius: 71px;
    color: #fff;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
}

.main.welcome .main__container-video-overlay-btn-name {
    pointer-events: none;
}

.main.welcome .main__container-video.active .video-placeholder {
    max-height: calc(100svh - (var(--head-foot-h) * 2));
}

.main__container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px;
    padding-top: 0;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.main__container-grid {
    --grid-gutter: 25px;
    width: 100%;
    /* transition: width 0.3s; */
}

.main__container-grid:after {
    content: "";
    display: block;
    clear: both;
}

.grid-sizer,
.main__container-video {
    --width-full: 100%;
    width: var(--width-full);
}

.gutter-sizer {
    width: var(--grid-gutter);
}

.main__container-video {
    overflow: hidden;
    float: left;
    margin-bottom: calc(var(--grid-gutter) - 5px);
    animation-duration: 0.2s;
    animation-fill-mode: both;
}

.main__container-video .number {
    position: absolute;
    visibility: hidden;
}

.main__container-video.active {
    animation-name: active-width;
    z-index: 1;
}

.main__container-video.inactive {
    height: calc(50svh - var(--head-foot-h));
    animation-name: inactive-width;
    z-index: 0;
}

@keyframes active-width {
    to {
        width: var(--width-full);
    }
}

@keyframes inactive-width {
    from {
        width: var(--width-full);
    }
}

.video-placeholder {
    position: relative;
    max-height: 100%;
    aspect-ratio: 16 / 9;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    overflow: hidden;
    background-color: #e8f0fd;
}

.video-placeholder video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    z-index: 2;
}

/* .video-placeholder i {
    color: #fff;
    line-height: 0px;
    font-size: 10vh;
    position: absolute;
} */

.video-placeholder__avatar {
    --radius: 50%;
    height: 50%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-placeholder__avatar-initials {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: 700;
    line-height: normal;
    color: #fff;
}

.main__container-video.active .video-placeholder {
    max-height: 40svh;
    border-radius: 15px;
}

.video-placeholder img {
    position: absolute;
    max-width: 150px;
    max-height: 150px;
    width: calc(100% - 0px);
    height: calc(100% - 0px);
    border-radius: 100%;
    z-index: 1;
}

.main__container-video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    z-index: 2;
}

.main__container-video-overlay-btn {
    position: absolute;
    height: 60px;
    width: fit-content;
    min-width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ccdfff;
    border-radius: 60px;
    cursor: pointer;
    color: #000c38 !important;
    z-index: 1;
}

.main__container-video-overlay-btn i {
    color: #000c38 !important;
}

.main__container-video-overlay-btn-video {
    bottom: 14px;
    left: 14px;
    cursor: pointer;
}

.main__container-video-overlay-btn-name {
    bottom: 14px;
    left: 14px;
    max-height: 35px;
    padding: 10px 15px;
    padding-left: 10px;
    cursor: pointer;
}

.main__container-video-overlay-btn-name span {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
}

.main__container-video-overlay-btn-name i {
    position: relative;
    font-size: 16px;
    margin-right: 8px;
}

.main__container-video-overlay-btn-name i:before {
    font-weight: 900 !important;
}

.main__container-video.active .main__container-video-overlay-btn-name {
    padding: 10px 20px;
}

.main__container-video.active .main__container-video-overlay-btn-name i {
    display: none;
}

.main__container-video-overlay-btn {
    height: 35px;
    min-width: 35px;
}

.main__container-video-overlay-btn-sound {
    bottom: 14px;
    right: 14px;
}

.main__container-video-overlay-btn-sound i,
.main__container-video-overlay-btn-video i {
    font-size: 22px;
}

.main__container-video-overlay-btn-rec {
    display: none;
    top: 14px;
    left: 14px;
    padding: 0 20px 0 10px;
}

.main__container-video-overlay-btn-rec i {
    font-size: 20px;
}

.main__container-video-overlay-btn-rec span {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
}

.main__container-video-overlay-btn-fullscreen {
    display: none;
    top: 14px;
    right: 14px;
}

.main__container-video-overlay-btn-fullscreen i {
    font-size: 18px;
}

.main__container-btn-hover {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
}

.main__container-video-overlay:hover .main__container-btn-hover {
    opacity: 1;
    pointer-events: auto;
}

/* Scrollbar */
.calendar__box-items__wrapper,
.calendar__box__details {
    scrollbar-width: thin;
    scrollbar-color: #000c38 #dfebe1;
}

.time-picker__form__input__dropdown::-webkit-scrollbar,
.calendar__box-items__wrapper::-webkit-scrollbar,
.calendar__box__details::-webkit-scrollbar {
    width: 3px;
    height: 6px;
}

.calendar__box-wrapper::-webkit-scrollbar-track,
.calendar__box__details::-webkit-scrollbar-track {
    background: transparent;
}

.time-picker__form__input__dropdown::-webkit-scrollbar-thumb,
.calendar__box-items__wrapper::-webkit-scrollbar-thumb,
.calendar__box__details::-webkit-scrollbar-thumb {
    background-color: #000c38;
    border-radius: 3px;
}

.time-picker__form__input__dropdown::-webkit-scrollbar-track-piece:end,
.calendar__box-items__wrapper::-webkit-scrollbar-track-piece:end,
.calendar__box__details::-webkit-scrollbar-track-piece:end {
    background: #dee1e7;
    margin-bottom: 10px;
}

.time-picker__form__input__dropdown::-webkit-scrollbar-track-piece:start,
.calendar__box-items__wrapper::-webkit-scrollbar-track-piece:start,
.calendar__box__details::-webkit-scrollbar-track-piece:start {
    background: #dee1e7;
    margin-top: 10px;
}

/*--------------------------------------------------------------
# Home
--------------------------------------------------------------*/
.main.home {
    height: calc(100svh - ((var(--head-foot-h))));
}

.main__container-home {
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 10px;
}

.main__container-home .main__container-img img {
    width: 100%;
}

.main__container-home .main__container-description {
    color: #000c38;
    text-align: center;
    font-size: 20px;
}

.main__container-home .main__container-description span {
    font-weight: 700;
}

.main__container-home .main__container__form {
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-wrap: wrap; */
}

.home-btn {
    width: 240px;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 15px 10px 15px 20px;
    gap: 15px;
    border-radius: 20px;
    cursor: pointer;
    background: #000c38;
    color: #fff;
    text-align: center;
    font-size: 20px;
    border: none;
    outline: none;
}

.home-btn.welcome {
    width: 160px;
    height: 60px;
    padding: 15px 20px;
    color: #fff;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
}

.main__container__form-left {
    display: flex;
    align-items: center;
    margin-right: 10px;
    flex-wrap: wrap;
    gap: 10px;
}

.main__container__form .main__container__form-left .home-dropdown__container {
    flex-grow: 1;
}

.main__container__form__btn {
    /* padding: 15px; */
    border-radius: 20px;
    border: none;
    cursor: pointer;
    color: #80868b;
    background-color: #fff;
    font-weight: 600;
    line-height: 15px;
    transition: color 0.3s;
}

.main__container__form__btn:hover {
    color: #000c38;
}

.main__container-home .main__container-input {
    position: relative;
    flex-grow: 1;
}

.main__container-home .main__container-input input {
    width: 100%;
    padding: 15px 0px 15px 45px;
    color: #000c38;
    font-size: 20px;
    border-radius: 20px;
    border: none;
    box-shadow: inset 0 0 0 1px #000c38;
    /* background: #fff; */
}

.main__container-home .main__container-input i {
    position: absolute;
    top: 50%;
    left: 20px;
    font-size: 20px;
    transform: translateY(-50%);
}

/* Calendar */
.main__container-calendar {
    padding: 20px;
}

.main__container-calendar.calendar-open {
    right: 0;
}

.main__calendar__icon {
    width: 80px;
    height: 50px;
    position: absolute;
    top: 0;
    right: -200px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    color: #fff;
    background-color: #000c38;
    border-radius: 25px 0 0 25px;
    font-size: 25px;
    transition:
        width 0.3s,
        right 0.3s;
}

.main__calendar__icon i {
    cursor: pointer;
}

.main__calendar__icon.calendar-open {
    width: 100%;
    background-color: #000c38;
}

.main__calendar__icon i:last-child {
    display: none;
}

.react-calendar {
    max-width: 300px;
    padding-bottom: 40px;
    border: none;
    border-bottom: 1px solid #dcdfe3;
}

.react-calendar button {
    padding: 10px 0;
    margin: 5px 0;
    border: none;
    outline: none;
    color: #222b45;
    background-color: #fff;
    font-style: italic;
    font-size: 15px;
    /* text-align: center; */
    font-weight: 300;
    line-height: 20px;
}

.react-calendar__navigation {
    display: flex;
    margin: 0 20px;
}

.react-calendar__navigation button {
    padding: 0;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
}

button.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__month-view__weekdays__weekday {
    color: #80868b;
    font-style: italic;
    text-align: center;
}

button.react-calendar__tile--rangeBothEnds {
    color: #fff;
    font-style: normal;
    font-weight: 700;
    border-radius: 10px;
    background: #000c38;
}

.react-calendar button.react-calendar__navigation__arrow {
    font-size: 30px;
    font-style: normal;
}

.react-calendar__navigation__label__labelText {
    color: #222b45;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.react-calendar-label-year {
    color: #80868b;
    font-size: 12px;
    font-weight: 300;
}

.home-calendar__meetings {
    display: flex;
    flex-direction: column;
    /* height: calc(100svh - var(--head-foot-h) - 550px);
    overflow: auto; */
    gap: 10px;
    margin-top: 35px;
}

.home-calendar__meeting {
    padding: 25px 20px;
    border-radius: 20px;
    background-color: #091437;
    cursor: pointer;
    flex-grow: 1;
}
.home-calendar__meeting__duration {
    color: #ced0d7;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.75px;
}

.home-calendar__meeting__duration.more-info {
    padding: 10px;
    border-radius: 15px;
    color: #000c38;
    text-align: center;
    background-color: #000c3824;
    cursor: pointer;
}

.home-calendar__meeting__title {
    margin-top: 8px;
    color: #fff;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 1px;
}

/*--------------------------------------------------------------
# Settings
--------------------------------------------------------------*/
.main.settings {
    width: calc(100svw - var(--side-left-w));
    height: calc(100svh - 75px - var(--head-foot-h));
    padding: 20px;
}

.main.settings .settings__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0;
}

.main.settings .settings__header img {
    width: 30px;
    height: 30px;
}

.main.settings .settings__container .settings__title,
.main.settings .settings__container .settings__subtitle {
    color: #000c38;
    font-size: 26px;
    line-height: 36px;
}

.main.settings .settings__container .settings__title {
    font-weight: 700;
}

.main.settings .settings__container .settings__btns-container {
    display: flex;
    gap: 12px;
    align-items: center;
    margin: 35px 0;
}

.settings-btn {
    height: 44px;
    padding: 0px 20px;
    border-radius: 100px;
    border: none;
    background-color: #d8dfe7;
    overflow: hidden;
    color: #000c38;
    line-height: 24px;
}

.settings-btn.save {
    background-color: #000c38;
    color: #fff;
}

.settings-btn i {
    color: #000c38;
}

.settings-btn.subscription {
    width: 215px;
    padding: 12px 16px;
    border-radius: 8px;
    color: #fff;
    background: #000c38;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: -0.408px;
    text-transform: uppercase;
}

.settings__btn_container {
    margin-top: 20px;
    text-align: right;
}

.main.settings .settings__table-container {
    margin: 35px 0;
    border: 1px solid #eeeaf5;
    border-radius: 8px;
    /* overflow: hidden; */
    overflow-x: auto;
}

.main.settings .settings__table-container .settings__table {
    width: 100%;
}

.main.settings .settings__table thead {
    background: #000c38;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
}

.main.settings .settings__table .settings__table-body {
    color: #000c38;
    background-color: #fff;
    font-size: 14px;
}

.main.settings .settings__table thead th,
.settings__table-body td {
    padding: 12px;
    font-weight: 400;
}

.main.settings .settings__table .settings__table-body tr {
    border-bottom: 1px solid #eeeaf5;
}

.main.settings .settings__table .settings__table-body tr:last-child {
    border: none;
}

td.settings__table--logo {
    width: 70px;
}

td.settings__table--logo img {
    width: 100%;
    object-fit: contain;
}

.main.settings .settings__table td.settings__table--name,
.main.settings .settings__table td.settings__table--city,
.main.settings .settings__table td.settings__table--email {
    min-width: 250px;
}

.main.settings .settings__table td.settings__table--employee {
    min-width: 270px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.main.settings .settings__table td.settings__table--checkbox input {
    vertical-align: top;
}

.settings__table-body__name {
    font-weight: 600;
    line-height: normal;
}

.settings__table-body__position {
    color: #757575;
    font-size: 12px;
    margin-top: 4px;
}

.main.settings .settings__table .settings__table--status {
    padding-right: 0 !important;
    width: 100px;
}

.main.settings .settings__table th:last-child,
.main.settings .settings__table td:last-child {
    width: 50px;
    text-align: center;
}

.main.settings .settings__table.super-admin th:last-child,
.main.settings .settings__table.super-admin td:last-child {
    width: 250px;
    text-align: left;
}

.main.settings .participant-avatar {
    margin-right: 8px;
}

.settings__table-pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000c38;
    font-size: 15px;
    line-height: 22.5px;
}

.settings__table-pagination .pagination__count-per-page span,
.pagination__pages-number span {
    padding: 5px 13px;
    margin-left: 5px;
    border-radius: 4px;
    border: 1px solid #000c38;
    background: #f1f2f2;
    font-weight: 600;
    line-height: normal;
}

.settings__table-pagination .pagination__pages-container {
    display: flex;
    align-items: center;
}

.pagination__pages-number span {
    margin: 0 15px;
}

.pagination__pages-btns {
    margin-left: 15px;
}

.pagination__pages-btns i {
    stroke-width: 2px;
    stroke: #000c38;
    cursor: pointer;
}

.settings__table__row__edit {
    cursor: pointer;
}

.settings__table th.settings__table--position {
    width: auto;
    min-width: 200px;
}

.settings__table__select {
    display: flex;
    align-items: center;
}

.settings__table__select img,
.settings__table__select i {
    visibility: hidden;
    cursor: pointer;
}

.settings__table-body tr:hover .settings__table__select img,
.settings__table-body tr:hover .settings__table__select i {
    visibility: visible;
}

.settings__table--edit {
    padding: 3.5px 4px;
    background-color: #000c38;
}

.settings__btns-title--super {
    margin-top: 20px;
    margin-bottom: 8px;
    color: #000c38;
    font-size: 15px;
}

.settings__btns-container--super {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #000c38;
}

.settings.details .settings__btns-container--super {
    justify-content: space-between;
}

.settings__btns--super {
    display: flex;
    align-items: center;
    gap: 8px;
}

.settings__super-square {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00d1e1;
    color: #fff;
    cursor: pointer;
}

.settings-super-btn {
    position: relative;
    color: #999;
    font-size: 15px;
    border: none;
    background-color: #fff;
}

.settings-super-btn::after {
    content: attr(data-button-text);
    position: absolute;
    top: 1px;
    right: 6px;
    font-size: 15px;
    font-weight: 700;
    opacity: 0;
    transition: opacity 0.1s ease;
    color: #000c38;
    display: flex;
}

.settings-super-btn:hover::after {
    color: #000c38;
    opacity: 1;
}

.settings-super-btn:hover {
    color: transparent;
}

.settings-super-btn:disabled::after {
    content: none;
}

.settings-super-btn:disabled:hover {
    color: #999;
}

.settings-super-btn i {
    position: relative;
    z-index: 1;
    -webkit-text-stroke: 1px;
}

.settings-super-btn.save i {
    -webkit-text-stroke: 0px;
}

.settings-super-btn.save:disabled:hover i {
    color: #999;
}

.settings-super-btn.save:hover i {
    color: #000c38;
}

.settings-super-btn.active {
    color: #000c38;
    font-weight: 700;
}

.settings__table-logo {
    height: 30px;
    min-width: 30px;
}

.settings__table-logo img {
    height: 100%;
}

.main.calendar input[type="checkbox"],
.main.settings input[type="checkbox"] {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #000c38;
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
}

.main.calendar input[type="checkbox"]:checked,
.main.settings input[type="checkbox"]:checked {
    background-image: url("./assets/images/checked.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: #000c38;
}
/* Workspace details */
.settings__container__form {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

main.settings
    .settings__container
    .settings__container__form
    .settings__container__form-inputs
    .settings__btns-container {
    width: 100%;
    margin: 0;
}

.settings__container__form-inputs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-left: 30px;
}

.settings__container__form-input {
    flex-basis: calc(50% - 10px);
    display: flex;
    flex-direction: column;
    color: #000c38;
    font-size: 15px;
    line-height: 22.5px;
}

.settings__container__form-input .show-password {
    position: absolute;
    right: 5px;
    bottom: 11px;
}

.settings__container__form-input .show-password.distance-lg {
    bottom: 44px;
}

.settings__container__form-input button {
    align-self: flex-start;
    font-size: 14px;
    font-weight: 600;
}

.settings__container__form-input label {
    margin-bottom: 8px;
}

.settings__container__form-input input,
.settings__container__form-input select {
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #000c38;
    outline-color: #000c38;
    background: #fafafa;
    font-weight: 700;
}

.settings__container__form-input input:read-only {
    border: none;
    outline: none;
}

.settings__container__form-input input::placeholder {
    color: #000c38;
}

.settings__container__form-input.full-width {
    flex-grow: 2;
    flex-basis: 100%;
}

.settings__container__form-subtitle {
    margin-top: 20px;
    flex-grow: 2;
    flex-basis: 100%;
    color: #000c38;
    font-size: 15px;
    line-height: 22.5px;
}

.settings__container__form-subtitle.active {
    font-weight: 700;
}

.form-input__container {
    position: relative;
    width: 100%;
}
.form-input__container i,
.form-input__container img {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
}

.settings__container__form-input .error-message {
    align-self: flex-start;
    margin-top: 5px;
    margin-left: 20px;
    font-size: 13px;
    letter-spacing: 0.5px;
}

.settings__container__form-input .form-input__container #searchKey {
    padding-right: calc(1.5rem + 10px);
}

.settings__container__form-input .form-input__container input {
    padding-left: 35px;
}

.settings__container__form-input .form-input__container.bank-card input {
    padding-left: 65px;
}

.settings__container__form-input .settings__container__form-input--select {
    width: auto;
}
/* Workspace subscription */
.settings__container__cards {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-left: 50px;
    gap: 20px;
}
.settings__container__card {
    width: 260px;
    padding: 30px;
    border-radius: 20px;
    border: 1px solid #000c38;
}

.settings__container__card-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 45px;
}

.settings__container__card-top .settings__container__form-subtitle {
    margin-top: 0;
}

.settings__container__card__icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.settings__container__card__icon {
    cursor: pointer;
}

.settings__container__card-number,
.settings__container__card-name {
    color: #999;
    font-size: 15px;
    line-height: 22.5px;
}

.settings__container__card-number {
    font-weight: 700;
}

/* edit user */
.user-modal__label--checkbox:last-child {
    margin-left: 10px;
}

input[type="radio"] {
    display: none;
}

.radio-label {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%; /* Make it a circle */
    background-color: transparent; /* Default background color */
    border: 2px solid #000c38; /* Default border color */
    cursor: pointer;
}

input[type="radio"]:checked + .radio-label {
    background-color: #000c38;
}

input[type="radio"]:checked + .radio-label {
    border: none;
}

/*--------------------------------------------------------------
# Subscription
--------------------------------------------------------------*/
.main.subscription .settings__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
}
.main.subscription .settings__container .settings__container-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main.subscription .settings__container .settings__container-intro img {
    max-width: 450px;
    width: 100%;
    margin-bottom: 20px;
}

.main.subscription .settings__container .settings__container-title {
    text-align: center;
    color: #091437;
    font-size: 25px;
    font-weight: 600;
}

.main.subscription .settings__container .settings__container-title.semibold {
    font-weight: 500;
    cursor: pointer;
}

.main.subscription .settings__container .settings__container-title span {
    font-weight: 600;
}

.main.subscription .settings__cards-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.main.subscription .settings__cards-container hr {
    height: 2px;
    margin: 30px 0 40px;
    background-color: #fff;
    border: none;
    opacity: 1;
}

.main.subscription .settings__container .settings__container__card-subscription.light hr {
    background-color: #000c38;
}

.settings__container__card-subscription {
    position: relative;
    max-width: 350px;
    padding: 60px 60px 80px;
    border-radius: 44px;
    color: #fff;
    background: #091437;
}

.settings__container__card-subscription.light {
    color: #091437;
    background: #fff;
    box-shadow:
        0px 2px 6px 0px rgba(0, 0, 0, 0.04),
        0px 5px 18px 0px rgba(0, 0, 0, 0.06),
        0px 24px 83px 0px rgba(0, 0, 0, 0.1);
}

.card-subscription__free {
    padding: 10px 10px 20px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    text-align: center;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 0 0 45px 45px;
    font-weight: 600;
    box-shadow: 0 0 10px #000c38;
    cursor: pointer;
}
.settings__container__card-subscription .card-subscription__title {
    font-size: 28px;
    font-weight: 700;
    line-height: 50px;
}
.settings__container__card-subscription .card-subscription__subtitle {
    color: #00d1e1;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -1.3px;
}

.settings__container__card-subscription .card-subscription__subtitle.price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #091437;
    font-size: 18px;
}

.settings__container__card-subscription .card-subscription__subtitle.price span {
    font-size: 12px;
    letter-spacing: normal;
}

.settings__container__card-subscription .settings-btn {
    height: 57px;
    padding: 15px 20px;
    margin: 30px 0;
    border-radius: 9999px;
    background-color: #fff;
}

.settings__container__card-subscription.light .settings-btn {
    color: #fff;
    background-color: #eb5757;
}

.card-subscription__description {
    line-height: 25px;
}
.card-subscription__list {
    margin: 0;
    padding: 0;
}

.settings__container__card-subscription .card-subscription__list li {
    padding-inline-start: 20px;
    list-style-image: url("./assets/images/checkmark.svg");
    list-style-position: 10px;
}

.settings__container__card-subscription.light .card-subscription__list li {
    list-style-image: url("./assets/images/checkmark2.svg");
}

.subscription-icon {
    position: relative;
}

.subscription-icon .circle-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #eb5757 !important;
    font-size: 14px;
}

/*--------------------------------------------------------------
# Calendar
--------------------------------------------------------------*/
.main.calendar {
    width: calc(100svw - var(--side-left-w));
    height: calc(100svh - var(--head-foot-h));
    padding: 20px;
    overflow: auto;
}

.main.calendar .calendar__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.calendar__nav {
    width: 350px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000c38;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
}

.calendar__nav span {
    width: 250px;
    text-align: center;
}

.calendar__nav i {
    cursor: pointer;
}

.calendar__btn {
    padding: 20px;
    border-radius: 20px;
    border: none;
    background: #00d1e1;
    color: #fff;
    text-align: center;
    font-weight: 600;
}

.calendar__btn i {
    stroke-width: 2px;
}

.calendar__boxes {
    display: flex;
    height: calc(100svh - var(--head-foot-h) - 105px);
}

.calendar__box {
    height: 100%;
    flex: 1 1 0px;
    margin: 10px;
}

.calendar__box .calendar__box-items__wrapper {
    /* height: calc(100svh - var(--head-foot-h) - 144px); */
    overflow: auto;
    height: 100%;
}
.calendar__box .calendar__box__details {
    height: calc(100svh - var(--head-foot-h) - 220px);
    overflow: auto;
}

.calendar__box .calendar__box__title {
    color: #b6b6b6;
    font-size: 40px;
}

.calendar__box .calendar__box__title span {
    color: #000c38;
    font-size: 64px;
    font-weight: 700;
}

.calendar__box .calendar__box__title i {
    font-size: 30px;
    margin: 0 10px;
    cursor: pointer;
}

.calendar__box .calendar__box__item {
    display: flex;
    padding: 30px;
    margin-top: 20px;
    cursor: pointer;
}

.calendar__box__item__meetings-indicator {
    display: none;
}

.calendar__box .calendar__box__item.active,
.calendar__box .calendar__box__item.selected {
    background: #edf8f9;
}

.calendar__box__date {
    margin-right: 20px;
    text-align: center;
}

.calendar__box__today {
    padding: 4px 8px;
    border-radius: 4px;
    background: #00d1e1;
    color: #fff;
    text-align: center;
}

.calendar__box__day {
    color: #000c38;
    font-size: 64px;
    font-weight: 600;
    line-height: normal;
}

.calendar__box__day-number {
    color: #999;
    font-size: 20px;
    line-height: normal;
}

.calendar__meetings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
}

.calendar__meeting__title {
    color: #000c38;
    font-weight: 600;
    line-height: normal;
}

.calendar__meeting__duration,
.calendar__meeting__text,
.calendar__box__hours {
    margin-top: 5px;
    color: #999;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
}

.calendar__meeting__duration.dark {
    color: #000c38;
}

.calendar__meeting__duration span {
    display: inline-block;
    width: 40px;
    height: 2px;
    margin: 0 10px;
    vertical-align: middle;
    border-radius: 13px;
    background: #000c38;
}

.calendar__meeting__duration.reminder {
    color: #e7232c;
}

.calendar__meeting__duration.reminder span {
    background-color: #e7232c;
    margin-left: 0;
}

.calendar__box__details {
    position: relative;
    display: flex;
    margin-top: 20px;
}

.calendar__box__hours {
    padding: 10px;
    margin-top: 0;
}

.calendar__box__hours > div {
    margin-top: 30px;
}

.calendar__box__hours > div:first-child {
    margin-top: 0;
}

.calendar__box__hours span {
    display: inline-block;
    width: 10px;
    height: 2px;
    border-radius: 13px;
    background-color: #c0c0c0;
}

.calendar__box__hours > div.reminder {
    color: #000c38;
}

.calendar__box__hours span.reminder {
    width: 40px;
    background-color: #000c38;
}

.calendar__box__hours div:nth-child(2n + 1) > span {
    width: 20px;
}

.main.calendar .premeeting__participants-list {
    justify-content: flex-start;
    gap: 10px;
}

.main.calendar .premeeting__participants-list .participant-avatar {
    min-width: 40px;
    width: 40px;
    height: 40px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}

.main.calendar .premeeting__participants-list li:nth-child(2n) .participant-avatar {
    background-color: #00d1e1;
}

.main.calendar .premeeting__participants-list .participant-avatar > i {
    font-size: 25px;
}

.calendar__box__meet-container {
    position: relative;
    flex-grow: 1;
}

.calendar__box__meet-details {
    width: 90%;
    height: 190px;
    position: absolute;
    top: 6px;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 25px;
    border: 1px dashed #000c38;
    background: #fef4e8;
    cursor: pointer;
}

.calendar__box__meet-details.disabled {
    background-color: #eee !important;
    border-color: #000c38 !important;
}

.calendar__box__meet-details:nth-child(2) {
    top: 420px;
    height: 300px;
    border: 1px dashed #ff89a5;
    background: #fdecf0;
}

.calendar__meeting__duration.dark {
    right: 10px;
    position: absolute;
    bottom: 10px;
}

.calendar__box__meet-reminder {
    width: 85%;
    position: absolute;
    top: 305px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.calendar__box__meet-reminder .calendar__meeting__title {
    font-size: 40px;
    font-weight: 600;
}
.calendar__box__meet-reminder .calendar__meeting__description {
    color: #e7232c;
    font-size: 12px;
    line-height: 15px;
}
.calendar__box__meet-reminder .calendar__meeting__description span {
    font-weight: 600;
}

.calendar__box__form {
    position: relative;
    border-radius: 20px;
    background: #000c38;
    padding: 50px 30px 30px;
}

.calendar__btn:disabled {
    background-color: #ababab;
}

.calendar__btn.cancel,
.calendar__btn.join {
    min-width: 150px;
    width: 100%;
    flex: 1 1 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #f04465;
}

.calendar__btn.join {
    background-color: #00d1e1;
}

.calendar__box__form label {
    color: #c0c0c0b3;
}
.calendar__box__form input::placeholder,
.calendar__box__form-textarea textarea::placeholder {
    color: #fff;
}

.calendar__box__form input {
    border: none;
    color: #fff;
    background-color: transparent;
    font-weight: 600;
    outline: none;
}

.calendar__box__form-title .calendar__box__form-input input {
    border-bottom: 2px solid #fff;
    padding-bottom: 10px;
    font-size: 20px;
}

.calendar__box__form-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}

.calendar__box__form-input {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    min-width: 150px;
    width: 100%;
}

.calendar__box__form-date {
    margin: 20px 0;
}

.calendar__box__form-participants {
    margin-top: 40px;
    margin-bottom: 30px;
}

.calendar__box__form-participants .participants-container {
    margin-top: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.calendar__box__form-participants .premeeting__participants-list .participant-avatar.light {
    background-color: #fff;
    color: #000c38;
}

.premeeting__participants-list__item {
    position: relative;
}

.participant-remove-icon {
    display: none;
    position: absolute;
    top: -5px;
    right: -5px;
    font-size: 13px;
    padding: 0 3px;
    border-radius: 30px;
    color: white;
    background: black;
    box-shadow: 0 0 2px white;
    cursor: pointer;
}

.premeeting__participants-list__item:hover .participant-remove-icon {
    display: block;
}

.calendar__box__form__add-btn {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    color: #fff;
    background-color: #7b95ba;
    font-size: 23px;
    font-weight: 600;
    border-radius: 50%;
    box-shadow: 0 0 10px #7b95ba;
}

.calendar__box__form-textarea {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
}

.calendar__box__form-textarea textarea {
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    outline: none;
    background-color: #c0c0c01a;
    color: #fff;
    font-weight: 400;
}

.calendar__box__form-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.calendar__box__reverse-btn,
.calendar__box__save-btn {
    flex: 1 1 0px;
    min-width: 155px;
    width: 100%;
    height: 36px;
    border: none;
    border-radius: 20px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
}

.calendar__box__reverse-btn {
    border: 1px solid #00d1e1;
    background-color: transparent;
}

.calendar__box__save-btn {
    background-color: #00d1e1;
}

.arrow-pointer {
    position: absolute;
    bottom: 30px;
    left: -22px;
    color: #000c38;
    font-size: 40px;
}

.close-icon {
    display: block;
    position: absolute;
    top: 10px;
    color: #fff;
    cursor: pointer;
}

.calendar__box__form-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.calendar__box-wrapper {
    width: 100%;
    position: absolute;
}

/*--------------------------------------------------------------
# Premeeting
--------------------------------------------------------------*/
.premeeting__participants-list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.premeeting__participants-list span {
    color: #fff;
    font-size: 25px;
    font-weight: 700;
}

.main__container__participants-names {
    color: #000c38;
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    padding: 10px 0px;
}

.welcome__container-description {
    color: #000c38;
    text-align: center;
    font-size: 28px;
    line-height: 37px;
}

.welcome__container-description span {
    font-weight: 700;
}

/*--------------------------------------------------------------
# Aside Left
--------------------------------------------------------------*/
.aside-left {
    position: absolute;
    top: 0;
    width: var(--side-left-w);
    height: 100svh;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    z-index: 1002;
}

.aside-left__content {
    width: 72px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 42px;
    background-color: #fafafb;
    overflow: hidden;
}

.aside-left__content .side-menu-logo {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #000c38;
    cursor: pointer;
}

.aside-left__content .side-menu-logo.contain-logo {
    background-color: transparent;
}

.aside-left__content .side-menu-logo img.logo {
    width: 100%;
    height: 100%;
}

.side-menu__dropdown {
    position: fixed;
    top: var(--head-foot-h);
    left: 38px;
    height: 0;
    max-height: 244px;
    border-radius: 10px;
    overflow: auto;
    background-color: #fff;
    box-shadow: 0 0 10px #8f8e90;
    transition: height 0.2s;
    z-index: 5;
}

.side-menu__dropdown.settings {
    left: unset;
    right: 38px;
}

.side-menu__dropdown.new-meeting {
    max-height: 250px;
    position: absolute;
    top: 65px;
    right: 0;
    left: 0;
}

.side-menu__dropdown::-webkit-scrollbar {
    display: none;
}

.side-menu__dropdown {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.side-menu__dropdown .side-menu__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.side-menu__dropdown .side-menu__item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    border-bottom: 1px solid #eeeaf5;
    color: #000c38;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}

.side-menu__dropdown.settings .side-menu__item {
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
}

.side-menu__dropdown.new-meeting .side-menu__item {
    font-size: 16px;
    font-weight: 500;
}

.side-menu__dropdown .side-menu__item:hover {
    background-color: #e8f0fd;
}

.side-menu__dropdown.settings .side-menu__item:nth-child(3):hover {
    background-color: transparent;
    cursor: default;
}

.side-menu__dropdown .side-menu__item img {
    width: 40px;
    height: 40px;
    padding: 5px;
    object-fit: contain;
}

.side-menu__dropdown .side-menu__item.active img {
    border: 2px solid #000c38;
    border-radius: 10px;
}

.side-menu__dropdown .side-menu__item--languages {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
}

.side-menu__item--language {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.side-menu__item--language div.active:nth-child(2) {
    width: 5px;
    height: 5px;
    text-align: center;
    background-color: #3fc3d6;
    border-radius: 100%;
}

.aside-left__content .footer__content-video-control-btn {
    width: 72px;
    /* height: 72px; */
    height: 52px;
    margin-right: 0;
    border-radius: 0;
    margin-top: 3px;
    margin-bottom: 3px;
}

.aside-left__content .footer__content-video-control-btn i {
    color: #b6b6b6;
}

.aside-left__content .footer__content-video-control-btn:not(.aside-logo):hover {
    background-color: transparent;
}

.aside-left__content .footer__content-video-control-btn:not(.aside-logo):hover i {
    color: #000c38;
}

.aside-left__content .footer__content-video-control-btn:not(.aside-logo).active {
    box-shadow: inset -2px 0 0 0 #000c38;
    background-color: transparent;
}

.aside-left__content .footer__content-video-control-btn.active i {
    color: #000c38;
}

/*--------------------------------------------------------------
# Aside Right
--------------------------------------------------------------*/
.aside {
    position: absolute;
    top: var(--head-foot-h);
    left: calc(100vw + var(--side-right-w));
    width: var(--side-right-w);
    height: calc(100svh - var(--head-foot-h) - 20px);
    padding-right: 20px;
    background-color: #fff;
    transition: left 0.2s;
    z-index: 1;
}

.aside.welcome {
    display: none;
}

.aside.active {
    left: calc(100vw - var(--side-right-w));
}

.main,
.footer {
    transition: width 0.2s;
}

.main.active,
.footer.active {
    width: calc(100vw - var(--side-left-w));
}

.aside__content {
    border: 1px solid #e8f0fd;
    border-radius: 16px;
    /* box-shadow: 0px 0px 40px 0px #e8fbff; */
}

.aside__content--top {
    height: calc(100svh - (var(--head-foot-h) * 2) - 20px);
    display: flex;
    flex-direction: column;
}

.aside__content--bottom {
    height: var(--head-foot-h);
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aside__content-chat-input {
    height: 60px;
    width: 100%;
    padding: 7px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 88px;
    border: 1px solid #000c38;
}

.aside__content-chat-input-attach,
.aside__content-chat-input-send {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    font-size: 25px;
    cursor: pointer;
}

.aside__content-chat-input-type {
    flex-grow: 1;
}

.aside__content-chat-input-type input {
    width: 100%;
    height: 100%;
    padding-right: 15px;
    background-color: transparent;
    border: none;
    outline: none;
    color: #8d8f98;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
}

.aside__content-chat-input-type input::placeholder {
    color: #8d8f98;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
}

.aside__content-chat-input-attach {
    color: #8d8f98;
    border-radius: 50%;
    transition:
        background-color 0.3s,
        color 0.3s;
}

.aside__content-chat-input-attach:hover {
    background-color: #dfebff;
    color: #000c38;
}

.aside__content-chat-input-send {
    border-radius: 50%;
    background-color: #00d1e1;
    color: #fff;
    transition:
        background-color 0.2s,
        color 0.2s;
}

.aside__content-participants {
    height: 70px;
    min-height: 70px;
    overflow: hidden;
    transition: height 0.3s;
}

.aside__content-participants.active {
    height: 100%;
}

.aside__content-chat {
    position: relative;
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
}

.aside__content-header {
    position: absolute;
    width: calc(100% - 30px);
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 7px 17px 32px;
    background-color: #fff;
    opacity: 0.85;
    z-index: 1;
}

.participant-avatar-badge {
    position: absolute;
    top: -7px;
    left: -7px;
    height: 16px;
    width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.participant-avatar-badge i {
    font-size: 24px;
    color: #eb5757;
}

.participant-avatar-badge span {
    position: absolute;
    margin-bottom: 7px;
    color: #fff;
    font-weight: 500;
    font-size: 13px;
}

.message-typing {
    display: inline-block;
    margin-left: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #25293b;
    white-space: nowrap;
    opacity: 0.6;
}

.aside__content-list {
    height: calc(100%);
    margin-right: 25px;
    padding-top: 70px;
    padding-bottom: 35px;
    padding-left: 22px;
    padding-right: 25px;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #000c38 #dfebe1;
}

.aside__content-list--chat {
    padding-right: 10px;
}

.aside__content-list::-webkit-scrollbar {
    width: 3px;
}

.aside__content-list::-webkit-scrollbar {
    width: 3px;
}

.aside__content-list::-webkit-scrollbar-track {
    background: transparent;
}

.aside__content-list::-webkit-scrollbar-thumb {
    background-color: #000c38;
    border-radius: 3px;
}

.aside__content-list::-webkit-scrollbar-track-piece:end {
    background: #dfebff;
    margin-bottom: 35px;
}

.aside__content-list::-webkit-scrollbar-track-piece:start {
    background: #dfebff;
    margin-top: 35px;
}

.aside__content-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.aside__content-list ul .aside__content-list-participant {
    height: 69px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 7px;
    padding-right: 15px;
    background-color: #fff;
    border-radius: 95px;
    transition: box-shadow 0.3s;
}

.aside__content-list ul .aside__content-list-participant:hover {
    box-shadow: 0 0 0 5px #dfebff;
}

.aside__content-list ul .aside__content-list-participant.active {
    box-shadow: 0 0 0 3px;
}

.aside__content-list ul li:last-of-type {
    margin-bottom: 0;
}

.aside__content-list-title {
    flex-grow: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.aside__content .participant-avatar {
    margin-right: 15px;
}

.aside__content-list-chat .participant-avatar {
    border: 3px solid #fff;
}

.participant-avatar > i {
    font-size: 40px;
}

.aside__content-list-name {
    color: #25293b;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
}

.aside__content-list-buttons {
    display: flex;
    align-items: center;
}

.aside__content-list-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-left: 2px;
    cursor: pointer;
    background-color: transparent;
    border-radius: 50%;
    transition: background-color 0.3s;
}

.aside__content-list-button:hover {
    background-color: #dfebff;
}

.aside__content-list-button i {
    font-size: 24px;
}

.aside__content-list-button.active i {
    color: #000c38;
}

.aside__content-list-chat {
    display: flex;
    margin-bottom: 32px;
}

.aside__content-list-message {
    flex-grow: 1;
}

.aside__content-list-msg {
    padding: 9px 9px 9px 14px;
    margin-bottom: 12px;
    background-color: #fff;
    background-color: rgba(138, 138, 138, 0.08);
    border-radius: 10px;
}

.aside__content-list-msg:first-of-type {
    padding: 9px 9px 9px 14px;
}

.aside__content-list-msg:last-of-type {
    margin-bottom: 0;
}

.aside__content-list-chat-participant {
    color: #afafaf;
    font-size: 10px;
    font-weight: 500;
}

.aside__content-list-chat-text {
    color: #25293b;
    font-size: 15px;
    font-weight: 500;
    line-height: 29px;
}

.aside__content-list-timestamp {
    margin-left: 15px;
    padding-top: 4px;
    color: #a8a8a8;
    font-size: 10px;
    font-weight: 500;
    line-height: 22px;
    white-space: nowrap;
}

.aside__content-list-typing {
    position: absolute;
    bottom: 0;
    left: 0;
    width: var(--side-right-w);
    height: 30px;
    padding-left: 22px;
    align-items: center;
    background-color: #fff;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #25293b;
    white-space: nowrap;
    opacity: 0.85;
}

.aside__content-list-typing.active {
    display: flex;
}

.aside__content-chat .aside__content-list-typing {
    display: none;
}

.aside__content-chat.active .aside__content-list-typing {
    display: flex;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
    position: absolute;
    bottom: 20px;
    width: calc(100vw - var(--side-right-w));
    height: var(--head-foot-h);
    background-color: #fff;
}

.footer.welcome {
    width: 100vw;
}

.footer.welcome #chat-control {
    display: none;
}

.footer.welcome .footer__content-video-control {
    justify-content: center;
}

.footer__content {
    position: relative;
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    align-items: center;
}

.footer__content-video-control {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer__content-call-control {
    height: 60px;
    /* padding-left: 35px;
    padding-right: 35px; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    background: #eb5757;
    border-radius: 60px;
    white-space: nowrap;
    cursor: pointer;
}

.footer__content-video-control-btn {
    position: relative;
    width: 60px;
    height: 60px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafafb;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.1s;
}

.footer__content-video-control-btn.aside-logo {
    cursor: default;
}

.footer__content-video-control-btn .participant-avatar-badge {
    top: -6px;
    right: -6px;
    left: unset;
}

.footer__content-video-control-btn.active {
    color: #000c38;
}

.footer__content-video-control-btn > i {
    transition: color 0.1s;
}

.footer__content-video-control-btn:not(.aside-logo):hover {
    background-color: #e8f0fd;
}

.footer__content-video-control-btn:hover > i {
    /* color: #fff !important; */
}

/* .footer__content-video-control-btn:last-of-type {
    margin-right: 0;
} */

.footer__content-video-control-btn > i {
    font-size: 25px;
}

.footer__content-video-control-btn.button-call {
    width: 73px;
    height: 73px;
    background-color: #eb5757 !important;
}

.footer__content-video-control-btn.button-call > i {
    font-size: 30px;
    color: #fff;
}

/*--------------------------------------------------------------
# Login
--------------------------------------------------------------*/
.login__container {
    display: flex;
    flex-wrap: wrap;
}

.login__container__app-version {
    position: fixed;
    bottom: 10px;
    right: 20px;
    color: #a09f99;
    font-size: 12px;
}

.login__container-left,
.login__container-right {
    width: 50vw;
    /* height: 100svh; */
    min-height: 100svh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login__container-left {
    flex-wrap: wrap;
    padding: 1rem;
}

.login__container-left__wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 50px;
    border-radius: 50px;
    background-color: #e8f0fd;
}

.login__container-right {
    background-color: #e8f0fd;
    padding: 2rem;
}

.login__container-welcome {
    max-width: 380px;
    margin-bottom: 10px;
}

.login__container-img img {
    max-width: 450px;
    width: 100%;
    margin-bottom: 60px;
}

.login__container-title {
    color: #000c38;
    text-align: center;
    font-size: 32px;
    line-height: 37px;
}

.login__container-text {
    margin-top: 15px;
    color: #000c38;
    text-align: center;
}

.login__container-description {
    color: #000c38;
    text-align: center;
    font-size: 28px;
    line-height: 37px;
    margin-top: 40px;
}

.login__container-description span,
.login__container-title span {
    font-weight: 700;
}

.login__container-logo {
    margin-bottom: 30px;
}

.login-form {
    margin-top: 30px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    color: #a09f99;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.16px;
}

.form-group input {
    width: 100%;
    padding: 10px;
    border: none;
    box-shadow: 0 1px 0 0 #bdbdbd;
    color: #000c38;
    background-color: transparent;
    outline: none;
    transition: 0.3s;
}

.form-group input:focus {
    box-shadow:
        0 1px 0 0 #bdbdbd,
        inset 0 -1px 0 0 #bdbdbd;
}

.password-icon {
    position: relative;
}

.password-icon input[type="password"],
.password-icon input[type="text"] {
    padding-right: 35px;
}

.password-icon .show-password {
    position: absolute;
    right: 10px;
    top: 50%;
    cursor: pointer;
    color: #bebebe;
}

.login-submit {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.login-submit.forgotten-password {
    justify-content: flex-end;
}

.remember-me {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #091437;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

.log-in-btn {
    width: 153px;
    height: 55px;
    background-color: #000c38;
    border: none;
    padding: 7px 20px;
    border-radius: 56px;
    cursor: pointer;
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
}

.log-in-btn.light {
    color: #000c38;
    background-color: #fafafb;
}

.log-in-btn.next {
    margin-top: 10px;
}

.sign-up,
.forgotten-password a {
    margin-top: 38px;
    text-align: center;
    color: #424242;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
}

.forgotten-password {
    margin-top: 20px;
    text-decoration-line: none;
}

.sign-up a {
    color: #000c38;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    text-decoration-line: underline;
}

@media (max-width: 1024px) {
    .login__container-left__wrapper {
        padding: 30px;
    }
    .login__container-title {
        font-size: 28px;
    }

    .login__container-description {
        font-size: 20px;
    }

    .login__container-logo {
        margin-bottom: 0;
    }

    .login-form {
        margin-top: 10px;
    }

    .sign-up {
        margin-top: 20px;
    }

    .forgotten-password {
        margin-top: 0;
    }
}

@media (max-width: 768px) {
    .login__container-left,
    .login__container-right {
        width: 100vw;
    }

    .login__container-left {
        padding: 0;
        background-color: #e8f0fd;
    }

    .login__container-left__wrapper {
        padding: 16px;
    }

    .login__container-right {
        /* display: none; */
    }

    .log-in-btn {
        width: 95px;
        height: 40px;
    }

    .login__container-title {
        font-size: 20px;
    }

    .form-group {
        margin-bottom: 10px;
        line-height: normal;
    }

    .form-group label {
        font-size: 14px;
    }

    .form-group input {
        padding-bottom: 5px;
    }

    .login-submit {
        margin-top: 15px;
    }

    .login-submit.forgotten-password button {
        width: 100%;
    }
}

/*--------------------------------------------------------------
# Activation Page
--------------------------------------------------------------*/
.main__activation {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 20px;
}

.main__activation__cotainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 50px;
    border-radius: 50px;
    background: #e8f0fd;
}

.main__activation__img img {
    max-width: 54px;
    max-height: 54px;
}

.main__activation__title {
    margin: 40px 0;
    color: #000c38;
    font-size: 30px;
    font-style: normal;
    line-height: 37.5px;
    letter-spacing: -0.5px;
}

.main__activation__description {
    margin-bottom: 40px;
    color: #757575;
    font-size: 27px;
    font-style: normal;
    line-height: 37.5px;
    letter-spacing: -0.5px;
}

.main__activation__title span,
.main__activation__description span {
    font-weight: 700;
}

.activation-btn {
    width: 215px;
    height: 48px;
    padding: 12px 16px;
    color: #fff;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.4px;
    border: none;
    border-radius: 8px;
    background: #000c38;
}

.activation-btn.cancel {
    color: #000c38;
    background: #fff;
}

/*--------------------------------------------------------------
# Lobby Page
--------------------------------------------------------------*/
.main.lobby {
    width: calc(100svw - var(--side-left-w));
    height: calc(100svh - var(--head-foot-h) - 100px);
    padding: 20px;
    overflow: auto;
}

.lobby__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #000c38;
    background: #fafafa;
}

.lobby__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.lobby__item__info {
    color: #000c38;
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
}

.lobby__item__info img {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin-right: 10px;
}

.lobby__item__btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 90px;
    color: #fff;
    background: #000c38;
    cursor: pointer;
    transition: width 0.3s;
    overflow: hidden;
}

.lobby__item__btn-container .lobby__item__icon {
    visibility: visible;
}

.lobby__item__btn-container .lobby__item__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0;
    height: 100%;
    visibility: hidden;
    white-space: nowrap;
}

.lobby__item__btn span {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
}

.lobby__item__btn-container:hover {
    width: 170px;
}

.lobby__item__btn-container:hover .lobby__item__icon {
    visibility: hidden;
    width: 0;
}

.lobby__item__btn-container:hover .lobby__item__btn {
    width: 100%;
    visibility: visible;
}

/*--------------------------------------------------------------
# 404 NotFound Page
--------------------------------------------------------------*/
.not-found {
    height: 100vh;
    background-color: #e8f0fd;
    padding: 0 10px;
}

.not-found .not-found__container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.not-found .not-found__container .not-found__container-top {
    position: absolute;
    top: 100px;

    max-width: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.not-found .not-found__container .not-found__container-bottom {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 40px;
}

.not-found__container-bottom__info {
    text-align: center;
    margin-bottom: 40px;
    flex-basis: 33%;
}

.not-found__container-bottom__info .not-found__container-img {
    box-shadow: inset 0 -2px #000c38;
}

.not-found__container_ > .not-found__container-img {
    flex-basis: 33%;
}

.not-found__container-title {
    color: #000c38;
    text-align: center;
    font-size: 35px;
    font-weight: 700;
    line-height: 50px;
}

.not-found__container-description {
    color: #000c38;
    text-align: center;
    font-weight: 400;
    line-height: 28px;
}

.not-found__container .log-in-btn {
    width: 170px;
    height: 45px;
    text-align: center;
    padding: 0;
    font-size: 20px;
    font-weight: 600;
    line-height: 37px;
    text-transform: none;
}

.not-found__text {
    color: #000c38;
    text-align: center;
    font-size: 28px;
    line-height: 37px;
    margin-top: 40px;
}

.not-found__text > span {
    font-weight: 700;
}

/*--------------------------------------------------------------
# Confirm Modal
--------------------------------------------------------------*/
.confirm-modal {
    top: 50%;
    left: -100%;
    transform: translate(-50%, -50%);
    max-width: 600px;
    width: 100%;
    position: fixed;
    text-align: center;
    padding: 50px;
    border-radius: 50px;
    background-color: #e8f0fd;
    z-index: 1005;
    opacity: 0;
    transition: opacity 0.3s;
}

.confirm-modal.open {
    opacity: 1;
    left: 50%;
}

.confirm-modal.ok {
    padding: 20px;
    /* border-radius: 20px; */
}

.confirm__img img {
    width: 50px;
    height: 50px;
}

.confirm__content-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    font-size: 30px;
}

.confirm__content-top .confirm__content-top__icon {
    font-size: 25px;
    cursor: pointer;
}

.confirm__link-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    padding: 10px;
    border-radius: 5px;
    background-color: #dfe3ec;
}

.confirm__link-container .confirm__link-icon {
    margin-left: 10px;
    font-size: 20px;
    cursor: pointer;
}

.confirm__title {
    color: #000c38;
    font-size: 25px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.5px;
}

.confirm__text {
    margin: 45px 0;
    color: #000c38;
    font-size: 25px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.5px;
}

.confirm__description {
    text-align: left;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.5px;
}

.confirm__text span {
    font-weight: 700;
}

.confirm__btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.confirm__btns button {
    min-width: 135px;
    max-width: 150px;
    height: 48px;
    padding: 12px 16px;
    border: none;
    border-radius: 8px;
    color: #000c38;
    background-color: #fafafb;
    line-height: 22px;
    letter-spacing: -0.4px;
}

.confirm__btns button.confirm {
    color: #fff;
    background-color: #000c38;
}

/* user list */
.confirm-modal .confirm__list {
    margin-bottom: 20px;
    height: 250px;
    overflow: auto;
}
.confirm-modal .confirm__list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5px;
}

.confirm-modal .confirm__list-item:hover,
.confirm-modal .confirm__list-item.selected {
    background-color: #fff;
}

.confirm-modal .confirm__list-item.no-hover:hover {
    background-color: transparent;
}

.confirm-modal .confirm__list-item__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
}

.confirm-modal .confirm__list-item__image {
    width: 36px;
    min-width: unset;
    height: 36px;
}

.confirm-modal .confirm__list-item__image > i {
    font-size: 30px;
}

.confirm-modal .confirm__list-item__user-info {
    text-align: left;
    font-weight: 600;
    line-height: 1;
    word-break: break-word;
}

.confirm-modal .confirm__list-item__user-info span {
    font-weight: 400;
    font-size: 14px;
}

.confirm-modal .confirm__tabs {
    display: flex;
    align-items: center;
}

.confirm-modal .confirm__tab {
    flex-grow: 1;
    flex-basis: 50%;
    border-bottom: 2px solid #000c383b;
    transition: border-bottom-color 0.2s;
}

.confirm-modal .confirm__tab.active {
    border-bottom-color: #000c38;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    z-index: 1010;
    background: #00000075;
    opacity: 0;
    transition: opacity 0.2s;
}

.overlay.active {
    width: 100%;
    height: 100%;
    opacity: 1;
}

/*--------------------------------------------------------------
# Upload Image
--------------------------------------------------------------*/
.image-upload {
    text-align: center;
}

.details .image-upload {
    max-width: 300px;
}

.custom-file {
    display: flex;
    justify-content: center;
}

.custom-file-label {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* width: 170px;
    height: 160px; */
    padding: 0.5rem 0.75rem;
    border: 2px dashed #c0c0c0;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
}

.custom-file-label strong {
    color: #244220;
}

.custom-file-label i {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 75px;
    color: #dfebff;
}

.custom-file-label img {
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.custom-file--drag-drop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    /* width: 170px;
    height: 160px; */
    transform: translateX(-50%);
    border-radius: 10px;
}

.details .image-upload .custom-file strong {
    color: #000;
}

.image-upload__icons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    cursor: pointer;
}

.image-upload__icons-container span {
    color: #757575;
    font-size: 13px;
    line-height: 24px;
}

/*--------------------------------------------------------------
# Time Picker 
--------------------------------------------------------------*/
.time-picker {
    display: flex;
    align-items: center;
    color: #c0c0c0b3;
}

.time-picker .time-picker__label {
    margin-right: 10px;
}
.time-picker .time-picker__form__inputs {
    display: flex;
    align-items: center;
}

.time-picker .time-picker__form__input {
    position: relative;
}

.time-picker .time-picker__form__input .time-picker__form__input-text {
    width: 85px;
    height: 32px;
    padding: 5px 10px;
    color: #fff;
    border-bottom: 2px solid #00d1e1;
    cursor: pointer;
}

.time-picker .time-picker__form__inputs span {
    margin: 0 10px;
}

.time-picker .time-picker__form__input__dropdown {
    position: absolute;
    top: 40px;
    right: 0;
    left: 0;
    height: 0;
    color: #000c38;
    border-radius: 0 0 5px 5px;
    background-color: #fff;
    overflow: auto;
    z-index: 10;
    transition: height 0.2s;
    box-shadow: 0 0 5px #e3e3e3;
}

.time-picker .time-picker__form__input__dropdown.open {
    height: 168px;
}

.time-picker .time-picker__form__input__dropdown-item {
    padding: 10px;
    border-bottom: 1px solid #000c3824;
    font-size: 14px;
}

.time-picker .time-picker__form__input__dropdown-item:hover {
    background-color: #00d2e129;
}

/*--------------------------------------------------------------
# Media Queries
--------------------------------------------------------------*/
@media (max-width: 767px) {
    .main__container {
        overflow: auto;
        padding-top: 20px;
    }

    .not-found .not-found__container .not-found__container-top {
        position: static;
    }

    .not-found .not-found__container {
        justify-content: space-around;
    }

    .not-found .not-found__container .not-found__container-bottom {
        gap: 0;
    }
    .not-found__text {
        white-space: nowrap;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media (max-width: 1200px) {
    /* .header__content-title .btn {
        padding: 0.25rem 0.75rem;
    } */

    .header__content-title .btn i {
        /* width: 22px;
        height: 22px;
        margin-right: 0; */
    }

    .header__content-title .btn span {
        /* display: none; */
    }

    .settings__container__card-subscription {
        padding: 30px;
    }

    .settings__container__card-subscription .card-subscription__list {
        margin-left: 20px;
    }

    .settings__container__card-subscription .card-subscription__list li {
        padding-inline-start: 10px;
    }

    .main__container-calendar {
        position: absolute;
        top: 50px;
        right: -100svw;
        height: calc(100svh - var(--head-foot-h));
        width: calc(100svw - var(--side-left-w));
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        padding: 10px;
        overflow: auto;
        background-color: #fff;
        transition: right 0.3s;
    }

    .home-calendar__meetings {
        width: 300px;
        margin-top: 0;
    }

    .main__calendar__icon {
        right: 0;
    }

    .main__calendar__icon.calendar-open i:last-child {
        display: block;
    }

    .main__calendar__icon.calendar-open i:first-child {
        display: none;
    }

    .calendar__boxes {
        /* flex-wrap: wrap; */
        flex-direction: column;
    }

    .calendar__meetings {
        display: none;
    }

    .calendar__box:first-child {
        width: 100%;
    }

    .calendar__box:nth-child(3) {
        width: 100%;
        position: absolute;
        top: 0;
        right: -1500px;
        bottom: 0;
        margin: 0;
        background-color: #000c38;
        transition: right 0.3s;
    }

    .calendar__box:nth-child(3).open-modal {
        right: 0;
    }

    .calendar__box-wrapper {
        top: 0;
        bottom: 0;
    }

    .calendar__box__form {
        height: 100%;
        padding-top: 50px;
        overflow: auto;
    }

    .arrow-pointer {
        display: none;
    }

    .calendar__box .calendar__box-items__wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: auto;
    }

    .calendar__box .calendar__box__details {
        height: calc(100svh - var(--head-foot-h) - var(--week-h) - 270px);
    }

    .calendar__box .calendar__box__item {
        min-height: var(--week-h);
        min-width: 120px;
        position: relative;
        align-items: flex-end;
        margin-top: 0;
    }

    .calendar__box__date {
        margin-right: 0;
    }

    .calendar__box__item__meetings-indicator {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 65px;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #00d1e1;
        color: #fff;
        font-size: 12px;
    }

    .card-subscription__free {
        position: static;
        padding: 5px;
        margin-top: 15px;
        font-size: 13px;
        border-radius: 10px;
    }
}

@media (max-width: 992px) {
    .main__container-video.inactive {
        height: unset;
    }

    .header__content-user {
        width: auto;
        min-width: unset;
        padding-right: 10px;
        padding-left: 10px;
    }

    .header__content-user-profile {
        height: 60px;
        width: fit-content;
        padding-left: 10px;
        padding-right: 10px;
    }

    .header__content-user-name {
        display: none;
    }

    .main__container {
        scrollbar-width: thin;
        scrollbar-color: #000c38 #dfebe1;
    }

    .main__container::-webkit-scrollbar {
        width: 3px;
    }

    .main__container::-webkit-scrollbar {
        width: 3px;
    }

    .main__container::-webkit-scrollbar-track {
        background: transparent;
    }

    .main__container::-webkit-scrollbar-thumb {
        background-color: #000c38;
        border-radius: 3px;
    }

    .main__container::-webkit-scrollbar-track-piece:end {
        background: #dee1e7;
        margin-bottom: 20px;
    }

    .main__container::-webkit-scrollbar-track-piece:start {
        background: #dee1e7;
        margin-top: 20px;
    }

    .video-placeholder img {
        max-width: 100px;
        max-height: 100px;
    }

    .not-found__container-title {
        font-size: 25px;
        line-height: 30px;
    }

    .not-found__text {
        font-size: 18px;
        line-height: 20px;
        margin-top: 20px;
    }

    .not-found__container-bottom .not-found__container-img {
        align-self: flex-start;
        height: 80%;
    }

    .not-found__container-bottom .not-found__container-img img {
        height: 100%;
    }

    .main.welcome .main__container-welcome-h {
        font-size: 25px;
    }

    .home-btn.welcome {
        height: 40px;
        font-size: 16px;
        border-radius: 15px;
    }

    .settings.details {
        overflow: auto;
    }

    .settings__container__form {
        flex-wrap: wrap;
        justify-content: center;
    }

    .settings__container__form .settings__container__form-inputs {
        margin-left: 0;
    }

    .settings__container__form .settings__container__form-input {
        flex-basis: 100%;
    }

    .settings.details .image-upload {
        margin-bottom: 20px;
    }

    .settings__container__form-input:nth-child(even) {
        order: 1;
    }

    .settings__container__form-inputs.no-order .settings__container__form-input:nth-child(even) {
        order: unset;
    }

    .main.subscription {
        overflow: auto;
    }

    .main.subscription .settings__container {
        justify-content: flex-start;
    }

    .main.subscription .settings__container.intro {
        justify-content: center;
    }

    .main.subscription .settings__container .settings__container-title {
        margin-bottom: 15px;
        font-size: 20px;
    }

    .main.subscription .settings__cards-container {
        flex-direction: column;
        justify-content: flex-start;
        gap: 15px;
    }
}

@media (max-width: 768px) {
    * {
        --head-foot-h: 60px;
        --week-h: 120px;
    }

    #preloader {
        background: transparent;
    }

    .header {
        position: relative;
        top: 0;
        margin: 0;
        /* border-radius: 12px; */
        /* box-shadow: 0px 2px 16px -4px rgba(0, 0, 0, 0.4); */
        z-index: 1001;
    }

    .header__content-main {
        position: static;
    }

    .header__content-logo {
        width: fit-content;
        margin-right: 10px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 25px;
    }

    .header__content-user {
        padding-right: 5px;
    }

    .header__content-title {
        margin-right: 0;
        padding-left: 10px;
    }

    .header__content-line {
        left: 0;
        right: 0;
    }

    .header__content-title-main {
        /* padding-left: 10px; */
        font-size: 20px;
        margin-bottom: 0;
    }

    .header__content-timing {
        display: none;
    }

    .header__content-user-profile {
        height: 60px;
        width: fit-content;
        padding-left: 5px;
        padding-right: 5px;
    }

    .participant-avatar {
        width: 45px;
        height: 45px;
        min-width: 45px;
    }

    .participant-avatar > i {
        font-size: 30px;
    }

    .main {
        left: 0;
        width: 100vw;
        height: calc(100svh - 20px - (var(--head-foot-h) * 2));
        margin-top: 0px;
        margin-bottom: 10px;
    }

    .main.active {
        width: 100vw;
    }

    .main__container {
        height: calc(100% - 20px);
    }

    .main__container-video.active .video-placeholder {
        max-height: unset;
    }

    .main__container-btn-hover {
        opacity: 1;
        pointer-events: auto;
    }

    .main.welcome {
        flex-wrap: wrap;
        align-items: stretch;
        width: 100vw;
    }

    .main.premeeting {
        /* height: calc(100svh - 20px - (var(--head-foot-h) * 2)); */
    }

    .main.welcome .main__container {
        width: 100vw;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .main.welcome .main__container-welcome {
        width: 100vw;
        padding-top: 0;
    }

    .main.welcome .main__container-welcome-text {
        /* padding: 20px;
        margin-bottom: 20px; */
    }

    .main.welcome .main__container-welcome-p {
        color: #000c38;
        font-size: 14px;
        font-weight: 600;
    }

    .main__container__participants-names {
        font-size: 16px;
    }

    .main.welcome .main__container-welcome-h {
        font-size: 20px;
    }

    .main.home {
        height: calc(100svh - (var(--head-foot-h)) - 50px);
    }

    .main__container-home {
        gap: 15px;
    }

    .main__container-home .main__container__form {
        align-items: flex-end;
    }

    .main__container-home .main__container__form-left {
        gap: 5px;
    }

    .home-btn {
        width: 100%;
        height: 40px;
        border-radius: 15px;
    }

    .main__container__form__btn {
        padding: 10px;
    }

    .main__container-home .main__container-input input {
        padding: 8px 0 8px 45px;
        border-radius: 15px;
    }

    .main__container-home .main__container-description,
    .main__container-home .main__container-input input,
    .home-btn,
    .main__container-home .main__container-input input i {
        font-size: 16px;
    }

    .main__container-home .main__container-description.text {
        font-size: 14px;
    }

    .welcome__container-description {
        display: none;
    }

    .aside {
        padding-right: 0;
        width: calc(100vw - 20px);
        height: calc(100svh - 0px - (var(--head-foot-h) * 2));
        margin: 0 10px;
        /* margin-top: 10px; */
        border-radius: 12px;
        overflow: hidden;
        z-index: 10;
    }

    .aside__content {
        border: none;
    }

    .aside.active {
        left: 0;
    }

    .aside__content-header {
        height: 60px;
        padding-left: 10px;
        padding-right: 5px;
    }

    .aside__content--top {
        height: calc(100svh - 45px - (var(--head-foot-h) * 3));
    }

    .aside__content--bottom {
        height: 60px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .aside__content-chat-input {
        height: 45px;
    }

    .aside__content-chat-input-attach,
    .aside__content-chat-input-send {
        width: 35px;
        height: 35px;
    }

    .aside__content-list {
        margin-right: 0;
        padding-bottom: 35px;
        padding-left: 12px;
        padding-right: 15px;
    }

    .aside__content-list::-webkit-scrollbar-track-piece:end {
        background: #dfebff;
        margin-bottom: 12px;
    }

    .aside__content-list::-webkit-scrollbar-track-piece:start {
        background: #dfebff;
        margin-top: 12px;
    }

    .aside__content-list-typing {
        width: calc(100vw - 40px);
    }

    .aside-left {
        position: absolute;
        top: unset;
        height: auto;
        bottom: 5px;
        width: 100svw;
        padding-top: 0;
        padding-left: 0;
        padding-bottom: 0;
        z-index: 1001;
    }

    .aside-left.meeting {
        bottom: 16px;
        height: var(--side-left-w);
    }

    .aside-left__content {
        height: 41px;
        width: calc(100svw - 20px);
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        border-radius: 42px;
        background-color: #fff;
        background-color: #fafafb;
        /* background-color: red; */
        overflow: hidden;
        margin-left: 10px;
        margin-right: 10px;
    }

    .side-menu__dropdown {
        top: unset;
        bottom: var(--head-foot-h);
        left: 10px;
    }

    .side-menu__dropdown.settings {
        top: var(--head-foot-h);
        left: unset;
        right: 10px;
    }

    .side-menu__dropdown.new-meeting {
        top: 45px;
    }

    .aside-left.home .aside-left__content,
    .aside-left.premeeting .aside-left__content {
        justify-content: space-between;
    }

    .aside-left__content .aside-left__content-pages {
        display: flex;
    }

    .aside-left__content .footer__content-video-control-btn {
        width: 50px;
        height: 50px;
        margin-right: 0;
        border-radius: 0;
        margin-left: 3px;
        margin-right: 3px;
        background-color: transparent;
    }

    .aside-left__content .footer__content-video-control-btn i {
        color: #b6b6b6;
    }

    .aside-left__content .footer__content-video-control-btn:not(.aside-logo):hover {
        background-color: transparent;
    }

    .aside-left__content .footer__content-video-control-btn:not(.aside-logo):hover i {
        color: #000c38;
    }

    .aside-left__content .footer__content-video-control-btn:not(.aside-logo).active {
        box-shadow: inset 0 6px 0 0 #000c38;
        background-color: transparent;
    }

    .aside-left__content .footer__content-video-control-btn.active i {
        color: #000c38;
    }

    .footer {
        position: relative;
        bottom: -10px;
        width: calc(100vw - 20px) !important;
        margin: 0 10px;
        z-index: 1001;
    }

    .footer__content {
        padding-left: 10px;
        padding-right: 10px;
    }

    .footer__content-video-control-btn {
        margin-right: 10px;
    }

    .footer__content-call-control {
        height: 50px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .footer__content-video-control-btn {
        width: 40px;
        height: 40px;
    }

    .footer__content-video-control {
        justify-content: space-between;
    }

    .footer__content-video-control-btn.button-call {
        width: 50px;
        height: 50px;
    }

    .footer__content-video-control-btn.button-call i {
        font-size: 25px;
    }

    .header__content-title .btn {
        padding: 0.25rem 0.75rem;
    }

    .main.settings {
        width: 100%;
        height: calc(100svh - 60px - var(--head-foot-h));
        overflow: auto;
    }

    .main.settings .settings__container .settings__btns-container {
        flex-wrap: wrap;
        margin: 20px 0;
    }

    .main.settings .settings__table-container {
        margin: 20px 0;
    }

    .settings-btn {
        width: 100%;
    }

    .settings__table-pagination {
        flex-wrap: wrap;
        gap: 15px;
    }

    .details .image-upload {
        max-width: 150px;
    }

    .custom-file-label,
    .custom-file--drag-drop {
        width: 150px !important;
        height: 150px !important;
    }
    .image-upload__icons-container {
        justify-content: center;
        gap: 10px;
    }

    .settings__container__form-input .settings__container__form-input--select {
        width: 100%;
    }

    .settings__container__cards {
        flex-direction: column;
        justify-content: center;
        margin-left: 0;
        margin-top: 20px;
    }

    .settings__container__card {
        padding: 20px;
    }

    .main.settings .settings__header {
        margin: 0 0 15px;
    }

    .settings-btn.subscription {
        width: 100%;
    }

    .settings__btns--super {
        gap: 5px;
    }

    .settings__btns-container--super {
        padding: 12px 8px;
        margin-bottom: 15px;
    }

    .settings__super-square {
        width: 15px;
    }

    .settings-super-btn.save i {
        display: none;
    }

    .settings-super-btn {
        font-size: 13px;
    }

    .settings__btns-title--super {
        margin-top: 0;
    }

    .main.settings .settings__container .settings__title,
    .main.settings .settings__container .settings__subtitle {
        font-size: 20px;
    }

    .settings__container__form-input label {
        margin-bottom: 5px;
    }

    .settings__container__form .settings__container__form-inputs {
        gap: 10px;
    }

    .settings__container__card-subscription {
        padding: 20px;
        border-radius: 30px;
    }

    .settings__container__card-subscription .settings-btn {
        margin: 15px 0;
    }

    .main.calendar {
        width: 100svw;
        height: calc(100svh - var(--head-foot-h) * 2);
        padding: 10px;
    }

    .calendar__nav {
        color: #000c38;
        font-size: 18px;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
    }

    .calendar__btn {
        padding: 5px 10px;
        border-radius: 10px;
        order: 1;
    }

    .calendar__nav {
        order: 2;
        margin-top: 15px;
        width: 100%;
    }

    .calendar__nav span {
        width: 100%;
    }

    .calendar__box {
        margin-left: 0;
        margin-right: 0;
    }

    .calendar__box__form {
        padding: 50px 15px 15px;
    }

    .calendar__box .calendar__box__details {
        margin-top: 10px;
    }

    .calendar__box .calendar__box__item {
        justify-content: center;
        min-height: var(--week-h);
        min-width: 100px;
        padding: 10px;
    }

    .calendar__box .calendar__box__title {
        text-align: center;
        font-size: 18px;
    }

    .calendar__box .calendar__box__title span {
        font-size: 35px;
    }

    .calendar__box__today {
        font-size: 14px;
        padding: 2px 8px;
    }

    .calendar__box__item__meetings-indicator {
        top: 45px;
    }

    .calendar__box__day {
        font-size: 40px;
    }

    .calendar__box__day-number {
        font-size: 18px;
    }

    .main__container-calendar {
        height: calc(100svh - 35px - 60px - var(--head-foot-h));
        width: 100svw;
        padding: 10px;
        top: 40px;
    }

    .main__calendar__icon {
        right: 0;
        height: 40px;
        font-size: 20px;
    }

    .react-calendar {
        padding-bottom: 20px;
    }

    .react-calendar button {
        padding: 5px 0;
        margin: 0;
        font-size: 14px;
        line-height: normal;
    }

    .react-calendar__navigation {
        display: flex;
        margin: 0 20px;
    }

    .react-calendar button.react-calendar__navigation__arrow {
        font-size: 20px;
    }

    .react-calendar__navigation__label__labelText {
        font-size: 18px;
        line-height: normal;
    }

    .home-calendar__meeting {
        padding: 15px;
    }

    .home-calendar__meetings {
        width: 300px;
        margin-top: 20px;
    }

    .home-calendar__meeting__title {
        font-size: 14px;
    }

    .main.lobby {
        width: 100svw;
        height: calc(100svh - var(--head-foot-h) - 45px);
        padding: 10px;
    }

    .main.activation {
        background: #e8f0fd;
    }

    .main__activation {
        padding: 0;
    }

    .main__activation__cotainer {
        padding: 10px;
        border-radius: 0;
    }

    .main__activation__img img,
    .confirm__img img {
        width: 40px;
        height: 40px;
    }

    .main__activation__title {
        margin: 15px 0;
        font-size: 20px;
        line-height: normal;
    }

    .main__activation__description {
        margin-bottom: 15px;
        font-size: 17px;
        line-height: normal;
    }

    .activation-btn,
    .confirm__btns button {
        max-width: 100%;
        width: 100%;
        height: 35px;
        padding: 5px;
        font-size: 14px;
    }

    .confirm-modal {
        width: 95%;
        padding: 20px;
        border-radius: 20px;
    }

    .confirm-modal.add-people {
        padding: 15px;
    }

    .confirm__text {
        margin: 15px 0;
        font-size: 18px;
        line-height: normal;
    }

    .confirm-modal .confirm__list {
        margin-bottom: 10px;
        max-height: 100px;
        overflow: auto;
        height: 100%;
    }

    .confirm-modal .confirm__list-item__image {
        width: 25px;
        height: 25px;
    }

    .confirm-modal .confirm__list-item__image i {
        font-size: 20px;
    }

    .confirm-modal .confirm__list-item__user-info {
        font-size: 14px;
    }

    .confirm-modal .confirm__description {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 0;
    }

    .confirm__btns {
        flex-wrap: wrap;
        gap: 10px;
    }

    .confirm__content-top {
        margin-bottom: 8px;
        font-size: 20px;
    }

    .confirm__content-top .confirm__content-top__icon {
        font-size: 15px;
    }

    main.subscription .settings__container .settings__container-intro img {
        width: 180px !important;
    }

    .subscription-icon .circle-icon {
        top: 7px;
        right: 5px;
        left: unset;
    }

    .confirm__link-container {
        word-break: break-word;
    }
}

@media (max-height: 540px) {
    .not-found .not-found__container {
        justify-content: center;
    }
    .not-found .not-found__container .not-found__container-bottom {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        max-width: 100vw;
        height: 100%;
    }

    .not-found .not-found__container .not-found__container-top {
        top: 50px;
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.9);
        padding: 10px;
        border-radius: 10px;
    }

    .not-found__container-bottom .not-found__container-img {
        align-self: center;
    }

    .login__container-left__wrapper {
        padding: 8px 16px;
    }

    .login__container-logo {
        margin-bottom: 0px;
    }

    .login-form {
        margin-top: 0;
        margin-bottom: 5px;
    }

    .login__container-welcome,
    .form-group {
        margin-bottom: 5px;
    }

    .form-group input {
        padding: 0px 10px 5px;
    }

    .login-submit,
    .sign-up {
        margin-top: 10px;
    }

    .forgotten-password {
        margin-top: 0;
    }

    .login__container-img {
        max-height: 150px;
    }

    .log-in-btn {
        height: 40px;
    }

    .log-in-btn.next {
        margin: 5px 0;
    }

    .login__container-img img {
        margin-bottom: 0px;
        height: 100%;
    }
    .login__container-description {
        margin-bottom: 0px;
        margin-top: 0;
    }

    .login__container-right {
        padding: 0 2rem;
    }

    .welcome__container-description {
        display: none;
    }

    .aside-left {
        padding-top: 0;
        padding-bottom: 0;
    }

    .aside-left__content .footer__content-video-control-btn {
        height: 60px;
    }

    .subscription-icon .circle-icon {
        top: 10px;
        right: 15px;
    }

    .main.welcome {
        height: calc(100svh - ((var(--head-foot-h))));
    }

    .main.settings .settings__header {
        margin: 0 0 15px;
    }

    .react-calendar__navigation__label__labelText {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .calendar__box .calendar__box__details {
        height: calc(100svh - var(--head-foot-h) - 100px);
    }
    main.subscription .settings__container .settings__container-intro img {
        width: 180px !important;
    }

    .activation-btns {
        flex-wrap: nowrap !important;
        gap: 10px;
    }
}

@media (max-height: 568px) {
    .main.home {
        background-color: rgba(255, 255, 255, 0.8);
    }

    .main__container-home .main__container-img {
        height: 200px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }

    .main__container-home .main__container-img img {
        height: 100%;
    }

    .main__container-home {
        gap: 5px;
    }

    .main.welcome .main__container-welcome-h {
        padding: 0;
    }
}

@media (max-width: 768px) and (max-height: 540px) {
    .main.welcome {
        flex-wrap: nowrap;
        align-items: center;
        height: calc(100svh - 50px - ((var(--head-foot-h))));
    }

    .subscription-icon .circle-icon {
        top: 10px;
        right: 5px;
    }
}

@media (max-width: 320px) and (max-height: 480px) {
    .main.welcome {
        flex-wrap: wrap;
        height: calc(100svh - 50px - ((var(--head-foot-h))));
    }

    .main.welcome .main__container {
        padding: 0 10px;
    }

    .premeeting__participants-list span {
        font-size: 16px;
    }

    .participant-avatar {
        width: 35px;
        height: 35px;
        min-width: 35px;
    }

    .main__container__participants-names {
        padding: 0;
    }

    .aside-left.home .aside-left__content,
    .aside-left.premeeting .aside-left__content {
        justify-content: space-around;
    }

    .activation-btns {
        flex-wrap: wrap !important;
        gap: 10px;
    }
}

@media (min-width: 769px) and (max-height: 540px) {
    * {
        --head-foot-h: 60px;
    }

    .main.settings {
        height: calc(100svh - var(--head-foot-h));
        overflow: auto;
    }

    .main.settings .settings__container .settings__btns-container {
        margin: 20px 0;
    }

    .main.settings .settings__table-container {
        margin: 20px 0;
    }

    .main__container-calendar {
        height: calc(100svh - 40px - var(--head-foot-h));
        padding: 0 10px 10px;
        top: 40px;
    }

    .main__calendar__icon {
        right: 0;
        height: 40px;
        font-size: 20px;
    }

    .react-calendar {
        padding-bottom: 20px;
    }

    .react-calendar button {
        padding: 5px 0;
        margin: 0;
        font-size: 14px;
        line-height: normal;
    }

    .react-calendar__navigation {
        display: flex;
        margin: 0 20px;
    }

    .react-calendar button.react-calendar__navigation__arrow {
        font-size: 20px;
    }

    .react-calendar__navigation__label__labelText {
        font-size: 18px;
        line-height: normal;
    }

    .home-calendar__meetings {
        width: 300px;
        margin-top: 20px;
    }

    .home-calendar__meeting {
        padding: 15px;
    }

    .home-calendar__meeting__title {
        font-size: 14px;
    }

    .main.activation {
        height: calc(100svh - var(--head-foot-h));
    }

    .main__activation {
        padding: 0;
    }

    .main__activation__img img {
        width: 40px;
        height: 40px;
    }

    .main__activation__title {
        margin: 15px 0;
        font-size: 20px;
        line-height: normal;
    }

    .main__activation__description {
        margin-bottom: 15px;
        font-size: 17px;
        line-height: normal;
    }

    .activation-btn {
        width: 100%;
        height: 40px;
        padding: 8px;
    }

    .confirm-modal {
        padding: 20px;
        border-radius: 20px;
    }

    .confirm__text {
        margin: 15px 0;
        font-size: 18px;
        line-height: normal;
    }
}

.preloader-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 1000;
    overflow: hidden;
}

.new-preloader {
    --radius: 100px;
    position: absolute;
    width: calc(var(--radius) * 2);
    height: calc(var(--radius) * 2);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
    animation: preloader_wrap 1s ease-in-out 1;
    animation-fill-mode: both;
}

@keyframes preloader_wrap {
    0% {
        transform: scale(1.75);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.75);
    }
}

.square {
    width: var(--radius);
    height: var(--radius);
    height: calc(var(--radius) / 1.35);
    position: absolute;
    border-radius: 8px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: calc(var(--radius) * 1.25);
    overflow: hidden;
    animation-duration: 1.375s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    background-color: #e8f0fd;
    border: 2px solid #fff;
}

.square:nth-of-type(1) {
    /* background-color: #fa5667; */
    top: 0;
    left: 0;
    animation-name: square-1_anm;
}

.square:nth-of-type(2) {
    /* background-color: #7a45e5; */
    top: 0;
    right: 0;
    animation-name: square-2_anm;
}

.square:nth-of-type(3) {
    /* background-color: #1b91f7; */
    bottom: 0;
    right: 0;
    animation-name: square-3_anm;
}

.square:nth-of-type(4) {
    /* background-color: #fac24c; */
    bottom: 0;
    left: 0;
    animation-name: square-4_anm;
}

@keyframes square-1_anm {
    0%,
    100% {
        transform: translate(0, 0);
    }
    25% {
        transform: translate(0, 100%);
    }
    50% {
        transform: translate(100%, 100%);
    }
    75% {
        transform: translate(100%, 0);
    }
}

@keyframes square-2_anm {
    0%,
    100% {
        transform: translate(0, 0);
    }
    25% {
        transform: translate(-100%, 0);
    }
    50% {
        transform: translate(-100%, 100%);
    }
    75% {
        transform: translate(0, 100%);
    }
}

@keyframes square-3_anm {
    0%,
    100% {
        transform: translate(0, 0);
    }
    25% {
        transform: translate(0, -100%);
    }
    50% {
        transform: translate(-100%, -100%);
    }
    75% {
        transform: translate(-100%, 0);
    }
}

@keyframes square-4_anm {
    0%,
    100% {
        transform: translate(0, 0);
    }
    25% {
        transform: translate(100%, 0);
    }
    50% {
        transform: translate(100%, -100%);
    }
    75% {
        transform: translate(0, -100%);
    }
}
